import BaseInstance from "../base.instance";
import {
  REPORT_DICIPLINE,
  REPORT_REKAPITULASI,
  REPORT_REKAP_CUTI,
  REPORT_LAPORAN1G_DATA,
  REPORT_LAPORAN1G_EXCEL,
  REPORT_DETAIL_KEHADIRAN
} from "../constants";

const ReportService = {
  rekapitulasiKehadiran(data) {
    return BaseInstance.post(REPORT_REKAPITULASI, data);
  },
  kedisiplinanPegawaiList(data) {
    return BaseInstance.post(REPORT_DICIPLINE, data);
  },
  rekapCuti(data) {
    return BaseInstance.post(REPORT_REKAP_CUTI, data);
  },
  lampiran1GList(data) {
    return BaseInstance.post(REPORT_LAPORAN1G_DATA, data);
  },
  exportLampiran1G(params, type = "arraybuffer") {
    return BaseInstance.download(REPORT_LAPORAN1G_EXCEL, params, type);
  },
  detailKehadiran(data) {
    return BaseInstance.post(REPORT_DETAIL_KEHADIRAN, data);
  }
};

export default ReportService;
